// JS Dependencies: Bootstrap & JQuery
import 'bootstrap';
import 'wowjs';
//import 'jquery';
//import 'jquery-ui';

// Using the next two lines is like including partial view _ValidationScriptsPartial.cshtml
//import 'jquery-validation';
//import 'jquery-validation-unobtrusive';

// CSS Dependencies: Bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css/animate.min.css'

// Custom JS imports
//import '../ts/index.ts'
//import '../index.html';


// Custom CSS imports
import '../css/font-awesome.css';
import '../css/fonts.css';
import '../css/myglyphicons2.css';
import '../css/rpg-awesome.css';
import '../css/site.css';

// Import Images
import '../img/logo.png';
import '../img/favicon.ico';
import '../img/banner.png';
import '../img/bahrain.png';
import '../img/doha.png';
import '../img/dubai.png';
import '../img/indexAbout.png';
import '../img/indexCaseStudies1.png';
import '../img/indexStatBg.png';
import '../img/indexTestimonials.png';
import '../img/istanbul.png';
import '../img/london.png';
import '../img/paris.png';
import '../img/partner1.png';
import '../img/partner2.png';
import '../img/partner3.png';
import '../img/partner4.png';
import '../img/partner5.png';
import '../img/partner6.png';
import '../img/services.png';
import '../img/seychelles.png';
import '../img/studyabroad.png';
import '../img/tokyo.png';
import '../img/user.png';


console.log('The \'site\' bundle has been loaded!');